﻿// Patterns
// 1. Radio
//    <fieldset><div class="custom-control custom-radio"><input type="radio" />...
$.validator.setDefaults({
  highlight: function (element) {
    if ($(element).is(":radio")) {
      // do nothing
    }
    else {
      $(element).addClass('is-invalid');
    }
  },
  unhighlight: function (element) { $(element).removeClass('is-invalid'); },
  errorElement: 'div',
  errorClass: 'invalid-feedback',
  errorPlacement: function (error, element) {
    if (element.parent('.input-group').length || element.attr('type') === 'checkbox') {
      error.insertAfter(element.parent());
    }
    else if (element.hasClass('select2-hidden-accessible') && element.next('.select2.select2-container').length > 0) {
      // select2
      // See https://stackoverflow.com/questions/51723987/jquery-validate-errorplacement-in-select2-js
      error.insertAfter(element.next('.select2.select2-container'));
    }
    else if (element.is(":radio")) {
      error.appendTo(element.parent().parent());
    }
    else {
      error.insertAfter(element);
    }
  }
});